export const spPeriodList = [
  { key: '', name: 'None' },
  { key: 'month', name: 'Month' },
  { key: 'day', name: 'Day' },
];

export const spTypeList = [
  { key: 'next_job', name: 'Optimization' },
  { key: 'jobview', name: 'Recoup' },
  { key: 'jobview_reg', name: 'User Acquisition' },
];

export const jobMatchLimitType = [
  { key: '', name: 'None' },
  { key: 'fixed', name: 'Fixed # of clicks' },
  { key: 'percentage', name: 'Percentage' },
];

export const redirectList = [
  { key: 'jobview', name: 'Job View' },
  { key: 'jobview_na', name: 'Job View Native Apply' },
  { key: 'next_job', name: 'Next Job' },
  { key: 'next_job_emp', name: 'Next Job Emp' },
  { key: 'next_job_api', name: 'Next Job Api' },
  { key: 'next_job_int', name: 'Next Job Int' },
  { key: 'job_match_dl', name: 'Job Match DL' },
  { key: 'job_match_jobview', name: 'Job Match Job View' },
  { key: 'serp', name: 'SERP' },
  { key: 'reg_path', name: 'Reg Path' },
];

export const forceDistanceRedirectList = [
  { key: 'jobview', name: 'Job View' },
  { key: 'next_job', name: 'Next Job' },
];

export const intentTypeList = [
  { key: 'days', name: 'Time (days)' },
  { key: 'clicks', name: 'Clicks (filtered)' },
  { key: 'cost', name: 'Cost ($)' },
];

export const triggerBudgetList = [
  { key: 'day', name: 'Day' },
  { key: 'month', name: 'Month' },
  { key: 'week', name: 'Week' },
];

export const triggerBudgetActionList = [
  { key: '', name: 'None' },
  { key: 'next_job', name: 'Next Job' },
  { key: 'jobview', name: 'Job View' },
  { key: 'off', name: 'Turn Off' },
];

export const partnerTypeJobApi = [
  { key: 'publisher', name: 'Publisher' },
  { key: 'admin', name: 'Admin' },
];

export const descriptionLength = [
  { key: 'full', name: 'Full' },
  { key: 'short', name: 'Short' },
  { key: 'none', name: 'None' },
];

export const defaultApiSort = [
  { key: 'cpc', name: 'CPC' },
  { key: 'cpa', name: 'CPA' },
  { key: 'relevance', name: 'Relevance' },
  { key: 'distance', name: 'Distance' },
];

export const acceptRevTypeExport = [
  { key: 'ALL', name: 'All' },
  { key: 'CPC', name: 'CPC' },
  { key: 'CPA', name: 'CPA' },
];

export const payTypeExport = [
  { key: 'CPC', name: 'CPC' },
  { key: 'CPAC', name: 'CPAC' },
  { key: 'CPA', name: 'CPA' },
  { key: 'CPH', name: 'CPH' },
];

export const expiredWhere = [
  { key: 'default', name: 'Default' },
  { key: 'boomerang', name: 'Similar jobs' },
  { key: 'main_page', name: 'Main page' },
  { key: 'other_page', name: 'Other page' },
  { key: 'next_job', name: 'Next job' },
  { key: 'next_job_emp', name: 'Next job emp' },
];

export const todoTaskType = [
  { key: 'objective', name: 'S&O' },
  { key: 'account', name: 'Account' },
  { key: 'feeds', name: 'Feeds' },
  { key: 'prog', name: 'Programmatic' },
  { key: 'research', name: 'Research' },
  { key: 'discrepancy', name: 'Discrepancy' },
  { key: 'suggestion_box', name: 'Suggestion box' },
];

export const todoTaskPriority = [
  { key: '1', name: 'Urgent' },
  { key: '2', name: 'Important' },
  { key: '3', name: 'High' },
  { key: '4', name: 'Normal' },
  { key: '5', name: 'Low' },
];

export const updMassEditList = [
  { key: 'isActive', name: 'Activate' },
  { key: 'isDeactive', name: 'De-activate' },
  { key: 'updBidAdj', name: 'Update Bid Adj' },
  { key: 'updMaxCpc', name: 'Update Max Cpc' },
  { key: 'updMinCpc', name: 'Update Min Cpc' },
  { key: 'updMinCpcNorm', name: 'Update Min Cpc norm' },
  { key: 'updTqf', name: 'Update Test Intent' },
];

export const listFeedStatus = [
  { key: 'testing', name: 'Testing' },
  { key: 'pilot', name: 'Pilot' },
  { key: 'live', name: 'Live' },
  { key: 'optimize', name: 'Optimize' },
  { key: 'pause', name: 'On pause' },
  { key: 'closed', name: 'Closed' },
];

export const listState = [
  { key: 'pending', name: 'Pending' },
  { key: 'active', name: 'Active' },
  { key: 'archived', name: 'Archived' },
];

export const targetList = [
  { key: ' ', name: 'None' },
  { key: 'campaign', name: 'Campaign' },
  { key: 'pub_feed', name: 'Pub Feed' },
  { key: 'adv_feed', name: 'Adv Feed' },
];

export const trafficType = [
  { key: 'xml', name: 'XML' },
  { key: 'link', name: 'Link' },
  { key: 'api', name: 'API' },
  { key: 'realtime', name: 'Real-Time' },
];

export const priority = [
  { key: '0', name: 'N/A' },
  { key: '1', name: '1' },
  { key: '2', name: '2' },
  { key: '3', name: '3' },
  { key: '4', name: '4' },
  { key: '5', name: '5' },
];

export const level = [
  { key: '1', name: '1' },
  { key: '2', name: '2' },
  { key: '3', name: '3' },
  { key: '4', name: '4' },
  { key: '5', name: '5' },
  { key: '6', name: '6' },
  { key: '7', name: '7' },
  { key: '8', name: '8' },
  { key: '9', name: '9' },
  { key: '10', name: '10' },
];

export const customUrlSites = [
  { key: 'cmp.jobs', name: 'cmp.jobs' },
  { key: 'realjobs.ai', name: 'realjobs.ai' },
];

export const siteAdminColumns = ['siteAdminPub', 'siteAdminAdv', 'siteAdminID'];

export const siteAdminReportColumns = ['siteAdminPub', 'siteAdminAdv'];
// export const siteAdminReportColumns = ['siteAdminPub', 'siteAdminAdv', 'filterByTrkSrc'];
// export const siteAdminReportColumns = [];
