/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
import React, {
  FC, useState, useMemo, useRef, useEffect, useCallback, useContext,
} from 'react';
import Paper from '@material-ui/core/Paper';
import {
  Sorting,
  TableColumnWidthInfo,
  ChangeSet,
  TableSelection,
  IntegratedSummary,
  SummaryItem,
  SummaryType,
  GroupRow,
} from '@devexpress/dx-react-grid';
import {
  Table,
  TableHeaderRow,
  PagingPanel,
  TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui';
import { IconButton } from '@/ui';
import {
  ColumnExtensionI, ColumnI, ActionsEnum, ParamsMutationResponseI, RowI, TypeTableEnum, TypeFieldEnum,
} from '@/components/TableUniversal/types';
import {
  Plugin, Template, TemplatePlaceholder,
} from '@devexpress/dx-react-core';
import {
  mdiPlusCircle as AddIcon,
  mdiFilterVariantRemove as ResetFilter,
  mdiRefresh as RefreshIcon,
} from '@mdi/js';
import clsx from 'clsx';
import { ComponentResizeDetector } from '@/ui/ComponentResizeDetector';
import { saveItem, getItem } from '@/utils/storage';
import { STORAGE_KEY_CONFIG_TABLE, STORAGE_KEY_HIDDEN_TABLE } from '@/constants/storage';
import { isEqualCollection } from '@/utils/common';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import {
  difference, differenceWith, maxBy, minBy,
} from 'lodash';
import { GhostContext } from '@/providers/GhostProvider';
import NumberObj from '@/utils/number';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USER_SETTINGS, UPDATE_USER_SETTINGS } from '@/gql/query/common';
import { UserContext } from '@/providers/UserProvider';
import get from 'lodash/get';
import { ParamsMutationI, PropsI } from './types';
import { PopupRow } from './PopupRow';
import { PopupRemoveRow } from './PopupRemoveRow';
import { Cell } from './Cell';
import { CellHeader } from './CellHeader';
import useStyles from './styles';
import { TypeFilterEnum } from '../Filter/types';
import { Loading } from '../Loading';
import { StandardTable } from './Template/StandardTable';
import { GroupedTable } from './Template/GroupedTable';
import { CustomGroupedTable } from './Template/CustomGroupedTable';
import { StandardGroupedTable } from './Template/StandardGroupedTable';
import configHiddenGhostMode from './config';

const TableUniversal: FC<PropsI> = ({
  columnsExtension,
  isLoading,
  isActiveExpand = false,
  onChangeParams,
  resetChecked,
  params,
  rows,
  totalCount,
  onMutation,
  isEdit,
  hideAddButton,
  onHandle,
  onAction,
  customCss,
  customCellCss,
  handleFilter,
  filters,
  catalogs,
  summaryRow,
  isPaging = true,
  isColumnsFilter = false,
  loadingCss,
  nameTable,
  isRealtime,
  type,
  basedOnType,
  isGroup,
  group,
  subgroup,
  grouping,
  groupSummaryItems,
  defaultSort,
  AddPopupRow,
  EditPopupRow,
  CustomAddButton,
  titleAddRow,
  titleEditRow,
  refetch,
  isClone,
  isCopyValues,
  isVisible = true,
  columnsVisibility = [],
  columnsUnvisibility = [],
  columnsSiteAdminInvisibility = [],
}) => {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const { data: userSettings } = useQuery(GET_USER_SETTINGS, {
    variables: {
      settingsName: STORAGE_KEY_HIDDEN_TABLE,
      userEmail: user?.email,
    },
  });
  const userSettingsData = get(userSettings, 'getUserSettings.payload.node');
  const [updateUserSettings] = useMutation(UPDATE_USER_SETTINGS);
  const dataGradient = useMemo(() => {
    const gradientColumns = columnsExtension.filter((item) => [
      TypeFieldEnum.GRADIENT_PERCENT, TypeFieldEnum.GRADIENT_CURRENCY, TypeFieldEnum.GRADIENT_ARROW,
    ].includes(item.type));
    return gradientColumns.length ? gradientColumns.reduce((obj, cur) => {
      // TODO: tableRow.row.avgCpcDelta
      if (cur.columnName === 'avgCpc') {
        const max = maxBy(rows, (item) => item.avgCpcDelta);
        const min = minBy(rows, (item) => item.avgCpcDelta);
        return { ...obj, [`max${cur.columnName}`]: max ? max.avgCpcDelta : 0, [`min${cur.columnName}`]: min ? min.avgCpcDelta : 0 };
      }
      const max = maxBy(rows, (item) => item[cur.columnName]);
      const min = minBy(rows, (item) => item[cur.columnName]);
      return { ...obj, [`max${cur.columnName}`]: max ? max[cur.columnName] : 0, [`min${cur.columnName}`]: min ? min[cur.columnName] : 0 };
    }, {}) : undefined;
  }, [columnsExtension, rows]);

  const rowsPrepa = useMemo(() => rows.map((item: RowI, index: number) => (
    { ...item, index, dataGradient }
  )), [dataGradient, rows]);

  const [rowsGrid, setRowsGrid] = useState<RowI[]>(rowsPrepa.map((item: RowI) => ({ ...item, blinking: false })));
  useEffect(() => {
    if (isRealtime) {
      if (!isEqualCollection(rowsGrid.map((item) => omit(item, 'blinking')), rowsPrepa)) {
        setRowsGrid(rowsPrepa.map((item, index) => {
          const blinking = !(!rowsGrid[index] || !rowsGrid[index].__typename || (isEqual(item, omit(rowsGrid[index], 'blinking'))));
          return { ...item, blinking };
        }));
        setTimeout(() => {
          setRowsGrid(rowsPrepa.map((item: RowI) => ({ ...item, blinking: false })));
        }, 2000);
      } else if (rowsGrid.every((n) => n.blinking)) {
        setRowsGrid(rowsPrepa.map((item: RowI) => ({ ...item, blinking: false })));
      }
    } else {
      setRowsGrid(rowsPrepa.map((item: RowI) => ({ ...item, blinking: false })));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  // useMemo hooks
  const memoColumns = useMemo<ColumnI[]>(() => columnsExtension.map(
    (column: ColumnExtensionI) => ({
      name: column.columnName,
      title: column.title,
      customTitle: column.customTitle || column.title,
      filteringAdditional: column.filteringAdditional || {},
    }),
  ), [columnsExtension]);

  const [columns, setColumns] = useState(memoColumns);

  useEffect(() => {
    if (columnsSiteAdminInvisibility?.length > 0) {
      setColumns(columns.filter((item) => !columnsSiteAdminInvisibility.includes(item.name)));
    } else {
      setColumns(memoColumns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnsSiteAdminInvisibility]);

  const columnsOrder = useMemo<string[]>(() => columnsExtension.map(
    (column: ColumnExtensionI) => column.columnName,
  ), [columnsExtension]);

  const { isGhostMode } = useContext(GhostContext);
  const columnsHidden = useMemo<string[]>(() => {
    const storageHiddenTable = JSON.parse(getItem(STORAGE_KEY_HIDDEN_TABLE) || (userSettingsData || 'null'));
    const activeData = columnsExtension.filter((column: ColumnExtensionI) => column.hidden)
      .map((column: ColumnExtensionI) => column.columnName);
    const data = (storageHiddenTable && storageHiddenTable[nameTable])
      ? storageHiddenTable[nameTable]
      : activeData;
    // const newData = data.concat(activeData);
    const prepaData = uniq(difference(data.concat(columnsUnvisibility), columnsVisibility));

    if (isGhostMode && configHiddenGhostMode[nameTable]) {
      const { hidden, show } = configHiddenGhostMode[nameTable];
      return difference(uniq(prepaData.concat(hidden)), show);
    }

    return prepaData;
  },
  [columnsExtension, columnsUnvisibility, columnsVisibility, isGhostMode, nameTable, userSettingsData]);

  const columnsAdding = useMemo<ColumnExtensionI[]>(() => columnsExtension
    .filter((column: ColumnExtensionI) => column.addingEnabled),
  [columnsExtension]);

  const columnsEditing = useMemo<ColumnExtensionI[]>(() => columnsExtension
    .filter((column: ColumnExtensionI) => column.editingEnabled),
  [columnsExtension]);

  const leftFixedColumns = useMemo<(string | symbol)[]>(() => {
    const result = columnsExtension
      .filter((column: ColumnExtensionI) => column.fixed)
      .map((column: ColumnExtensionI) => (column.columnName));

    return [TableSelection.COLUMN_TYPE, ...result];
  },
  [columnsExtension]);

  const SummaryItems = useMemo<SummaryItem[]>(() => columnsExtension
    .filter((column: ColumnExtensionI) => column.summary)
    .map((column: ColumnExtensionI) => ({ columnName: column.columnName, type: column.summaryType || 'sum' })),
  [columnsExtension]);

  const columnsByKey = useMemo<object>(() => {
    const columnsByKey = {};

    columnsExtension.forEach((column: ColumnExtensionI) => {
      columnsByKey[column.columnName] = column;
    });

    return columnsByKey;
  }, [columnsExtension]);

  const initCurrentPage = useMemo<number>(() => (
    (params?.skip && params?.take) ? Number(params.skip / params.take) : 0), [params]);

  const isFilter = useMemo<boolean>(() => !!filters, [filters]);

  // useState hooks
  const [hiddenColumns, setHiddenColumns] = useState<string[]>(columnsHidden);

  useEffect(() => {
    if (difference(columnsHidden, hiddenColumns).length || difference(hiddenColumns, columnsHidden).length) {
      setHiddenColumns(columnsHidden);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnsHidden]);

  const [columnOrder, setColumnOrder] = useState<string[]>(columnsOrder);
  const [currentPage, setCurrentPage] = useState<number>(initCurrentPage);

  useEffect(() => {
    if (params?.skip === 0) {
      setCurrentPage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const [isPopup, setPopup] = useState<Record<string, boolean>>({
    add: false, edit: false, remove: false, clone: false,
  });
  const [deleteList, setDeleteList] = useState<number[]>([-1]);
  const [editIndex, setEditIndex] = useState<number>(-1);
  const editRowTitle = getEditTitle(rows[editIndex], titleEditRow);
  // const editRowTitle = rows[editIndex] && rows[editIndex].id
  //   ? `${titleEditRow}: ${rows[editIndex].id}`
  //   : `${titleEditRow}`;
  const [columnWidths, setColumnWidths] = useState<TableColumnWidthInfo[]>(columnsExtension);
  const [pageSizes] = useState<number[]>([5, 10, 25, 50, 100]);
  const [widthDetector, setWidthDetector] = useState(0);
  // custom hooks
  const refGrid = useRef<HTMLDivElement>(null);
  const refGridTable = useRef<HTMLTableElement>(null);

  const memoDownHandler = useCallback(downHandler, [refGridTable]);

  useEffect(() => {
    window.addEventListener('keydown', memoDownHandler);
    return () => {
      window.removeEventListener('keydown', memoDownHandler);
    };
  }, [memoDownHandler]);

  useEffect(() => {
    if (refGrid.current) {
      const { clientWidth } = refGrid.current;
      const visibleColumns = columnsExtension.filter(
        (col: ColumnExtensionI) => !hiddenColumns.includes(col.columnName),
      );
      const newColumnWidth = visibleColumns.map((col: ColumnExtensionI) => {
        const widthPercent = Math.floor(((col.widthPercent as number || 0) / 100) * (clientWidth - 10));
        const width = col.width && col.width > widthPercent ? col.width : widthPercent;
        return { ...col, width };
      }) as TableColumnWidthInfo[];
      const unvisibleColumns = columnsExtension.filter(
        (col: ColumnExtensionI) => hiddenColumns.includes(col.columnName),
      );
      const newUnvisibleColumnWidth = unvisibleColumns.map(
        (col: ColumnExtensionI) => ({ ...col, width: col.width }),
      ) as TableColumnWidthInfo[];
      const tableColumnWidths = newColumnWidth.concat(newUnvisibleColumnWidth);
      const storageConfigTable = JSON.parse(getItem(STORAGE_KEY_CONFIG_TABLE) || 'null');
      if (storageConfigTable && storageConfigTable[nameTable]) {
        const storageTableColumnWidths = tableColumnWidths.map((col) => {
          const storageColumn = storageConfigTable[nameTable]
            .find((item: TableColumnWidthInfo) => col.columnName === item.columnName) || {};
          return {
            columnName: storageColumn.columnName || col.columnName,
            width: storageColumn.width || col.width,
          };
        });
        setColumnWidths(storageTableColumnWidths);
      } else {
        setColumnWidths(tableColumnWidths);
      }
    }
  }, [columnsExtension, hiddenColumns, nameTable, widthDetector]);

  const CustomToolbar = () => (
    <Plugin name="CustomToolbar">
      <Template name="toolbarContent">
        {
          CustomAddButton && (
            <CustomAddButton handlePopup={handlePopup} />
          )
        }
        <TemplatePlaceholder />
        {
          (refetch) && (
            <IconButton path={RefreshIcon} onClick={() => refetch()} title="Refresh data" />
          )
        }
        {
          (isEdit && !CustomAddButton && !hideAddButton) && (
            <IconButton path={AddIcon} onClick={handlePopup('add')} title="Create new row" />
          )
        }
        {
          isFilter && handleFilter && (
            <IconButton path={ResetFilter} onClick={() => handleFilter(undefined, true)} title="Reset filter" />
          )
        }
      </Template>
    </Plugin>
  );

  const PagingContainer = (props: PagingPanel.ContainerProps) => (
    <PagingPanel.Container {...props} className={classes.row} />
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const TotalCellComponent = (props: TableSummaryRow.CellProps & {children?: any}) => {
    if (props?.children?.props?.columnSummaries.length) {
      const column = props.column.name;
      const value = Number(props?.children?.props?.columnSummaries[0].value);
      const total = summaryRow && column in summaryRow ? summaryRow[column] : value;
      const totalFixed = Number.isInteger(total) ? total : Number(total).toFixed(2);
      const number = NumberObj.create(total);
      const type = columnsExtension.find((item) => item.columnName === props.column.name)?.type;
      const lowerType = String(type).toLowerCase();
      return (
        <TableSummaryRow.TotalCell {...props}>
          { type && lowerType.includes('currency') && `${totalFixed}$` }
          { type && lowerType.includes('percent') && `${totalFixed}%` }
          { (type && !lowerType.includes('currency') && !lowerType.includes('percent')) && number.toString() }
        </TableSummaryRow.TotalCell>
        // <TableSummaryRow.TotalCell {...props}>
        //   { TypeFieldEnum.CURRENCY === type && `$${totalFixed}` }
        //   { TypeFieldEnum.COMPARE_WITH_PERCENT_CR === type && `${totalFixed}%` }
        //   { (TypeFieldEnum.CURRENCY !== type && TypeFieldEnum.COMPARE_WITH_PERCENT_CR !== type) && number.toString() }
        // </TableSummaryRow.TotalCell>
      );
    }
    return <TableSummaryRow.TotalCell {...props} />;
  };

  // const TotalCellComponent = (props: TableSummaryRow.CellProps & {children?: any}) => {
  //   if (props?.children?.props?.columnSummaries.length) {
  //     const value = Number(props?.children?.props?.columnSummaries[0].value);
  //
  //     if (summaryRow) {
  //       const column = props.column.name;
  //       const total = summaryRow && column in summaryRow ? summaryRow[column] : value;
  //       return <TableSummaryRow.TotalCell {...props}>{(total)}</TableSummaryRow.TotalCell>;
  //     }
  //
  //     const type = columnsExtension.find((item) => item.columnName === props.column.name)?.type;
  //     const total = Number.isInteger(value) ? value : value.toFixed(2);
  //     return (
  //       <TableSummaryRow.TotalCell {...props}>
  //         { TypeFieldEnum.CURRENCY === type && `$ ${total}` }
  //         { TypeFieldEnum.NUMBER === type && total }
  //         { TypeFieldEnum.PERCENT === type && `${total}%` }
  //         { (TypeFieldEnum.COMPARE_WITH_PERCENT === type || TypeFieldEnum.COMPARE_WITH_CURRENCY_BORDER === type) && (
  //         <span className={clsx({ [classes.increasing]: total > 0 }, { [classes.decreasing]: total < 0 })}>
  //           {`$ ${total}`}
  //         </span>
  //         )}
  //       </TableSummaryRow.TotalCell>
  //     );
  //   }
  //   return <TableSummaryRow.TotalCell {...props} />;
  // };

  const summaryCalculator = (type: SummaryType, rows: Array<any>, getValue: (row: any) => any) => {
    if (type === 'customSum') {
      const customSum = rows.reduce((prev: any, cur: any) => prev + (Number(getValue(cur)) || 0), 0) / rows.length;
      const numCustomSum = NumberObj.create(customSum);
      return numCustomSum.toString();
    }
    if (type === 'customAvg') {
      const customAvg = rows.reduce((prev: any, cur: any) => prev + (Number(getValue(cur)) || 0), 0) / rows.length;
      const numCustomAvg = NumberObj.create(customAvg);
      return numCustomAvg.toString();
    }
    return IntegratedSummary.defaultCalculator(type, rows, getValue);
  };

  const memoHandleRowComponent = useCallback(handleRowComponent, []);
  const memoHandleHeaderCellComponent = useCallback(handleHeaderCellComponent, [handleFilter]);
  const memoHandleCellComponent = useCallback(handleCellComponent, [columnsByKey, resetChecked, catalogs]);
  const memoHandleContentComponent = useCallback(handleContentComponent, []);
  const memoHandleSummaryItemComponent = useCallback(handleSummaryItemComponent, []);

  const prepaColumnWidths = useMemo(() => {
    const defaultWidth = columnsExtension.map((item) => item.columnName);
    const calcWidth = columnWidths.map((item) => item.columnName);
    return differenceWith(defaultWidth, calcWidth, isEqual).length === 0 ? columnWidths : columnsExtension;
  }, [columnWidths, columnsExtension]);

  return (
    <Paper className={clsx(classes.grid, customCss)} ref={refGridTable}>
      <Loading state={isLoading} className={loadingCss} />
      <ComponentResizeDetector widthDetector={widthDetector} setWidthDetector={setWidthDetector} refGrid={refGrid} />
      <>
        {(() => {
          switch (type) {
            case TypeTableEnum.STANDARD: return (
              <StandardTable
                rows={rowsGrid}
                columns={columns}
                SummaryItems={SummaryItems}
                summaryCalculator={summaryCalculator}
                params={params}
                handleChangeSorting={handleChangeSorting}
                columnsExtension={columnsExtension}
                isPaging={isPaging}
                isEdit={!!isEdit}
                isFilter={isFilter}
                isColumnsFilter={isColumnsFilter}
                currentPage={currentPage}
                handleChangePage={handleChangePage}
                handlePageSizeChange={handlePageSizeChange}
                totalCount={totalCount}
                handleCellComponent={memoHandleCellComponent}
                HandleRowComponent={memoHandleRowComponent}
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
                hiddenColumns={hiddenColumns}
                handleHiddenColumns={handleHiddenColumns}
                columnWidths={prepaColumnWidths}
                handleColumnWidthsChange={handleColumnWidthsChange}
                HeaderCellComponent={memoHandleHeaderCellComponent}
                CustomToolbar={CustomToolbar}
                TotalCellComponent={TotalCellComponent}
                leftFixedColumns={leftFixedColumns}
                pageSizes={pageSizes}
                PagingContainer={PagingContainer}
                isVisible={isVisible}
              />
            );
            case TypeTableEnum.GROUPED: return (
              <GroupedTable
                rows={rowsGrid}
                columns={columns}
                isActiveExpand={isActiveExpand}
                isGroup={!!isGroup}
                isColumnsFilter={isColumnsFilter}
                columnWidths={prepaColumnWidths}
                handleColumnWidthsChange={handleColumnWidthsChange}
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
                TotalCellComponent={TotalCellComponent}
                hiddenColumns={hiddenColumns}
                handleHiddenColumns={handleHiddenColumns}
                handleCellComponent={memoHandleCellComponent}
                HandleRowComponent={memoHandleRowComponent}
                group={group || ''}
                subgroup={subgroup || ''}
                grouping={grouping}
                HeaderCellComponent={memoHandleHeaderCellComponent}
                SummaryItems={SummaryItems}
                groupSummaryItems={groupSummaryItems}
                defaultSort={defaultSort || []}
                handleContentComponent={memoHandleContentComponent}
                handleSummaryItemComponent={memoHandleSummaryItemComponent}
              />
            );
            case TypeTableEnum.STANDARD_GROUPED: return (
              <StandardGroupedTable
                rows={rowsGrid}
                columns={columns}
                columnWidths={prepaColumnWidths}
                handleColumnWidthsChange={handleColumnWidthsChange}
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
                TotalCellComponent={TotalCellComponent}
                hiddenColumns={hiddenColumns}
                handleHiddenColumns={handleHiddenColumns}
                handleCellComponent={memoHandleCellComponent}
                HandleRowComponent={memoHandleRowComponent}
                grouping={grouping}
                HeaderCellComponent={memoHandleHeaderCellComponent}
                SummaryItems={SummaryItems}
                groupSummaryItems={groupSummaryItems}
                handleContentComponent={memoHandleContentComponent}
                handleSummaryItemComponent={memoHandleSummaryItemComponent}
                params={params}
                handleChangeSorting={handleChangeSorting}
                columnsExtension={columnsExtension}
                leftFixedColumns={leftFixedColumns}
                isEdit={!!isEdit}
                isFilter={isFilter}
                isColumnsFilter={isColumnsFilter}
                CustomToolbar={CustomToolbar}
              />
            );
            case TypeTableEnum.CUSTOM_GROUPED: return (
              <CustomGroupedTable
                rows={rowsGrid}
                columns={columns}
                groupSummaryItems={groupSummaryItems}
                SummaryItems={SummaryItems}
                HandleRowComponent={memoHandleRowComponent}
                handleCellComponent={memoHandleCellComponent}
                columnWidths={prepaColumnWidths}
                handleColumnWidthsChange={handleColumnWidthsChange}
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
                HeaderCellComponent={memoHandleHeaderCellComponent}
                TotalCellComponent={TotalCellComponent}
                hiddenColumns={hiddenColumns}
                handleHiddenColumns={handleHiddenColumns}
                CustomToolbar={CustomToolbar}
                handleContentComponent={memoHandleContentComponent}
                handleSummaryItemComponent={memoHandleSummaryItemComponent}
                isEdit={!!isEdit}
                isColumnsFilter={isColumnsFilter}
              />
            );
            default: return <h1>No table match</h1>;
          }
        })()}
      </>
      {
        isEdit && (
          <>
            {
              AddPopupRow
                ? (
                  <AddPopupRow
                    isOpen={isPopup.add}
                    onClose={handlePopup('close')}
                    onSubmit={handleAddSubmit}
                    title={titleAddRow || 'Add row'}
                    basedOnType={basedOnType || ''}
                  />
                )
                : (
                  <PopupRow
                    catalogs={catalogs}
                    isOpen={isPopup.add}
                    title={titleAddRow || 'Add row'}
                    basedOnType={basedOnType || ''}
                    onClose={handlePopup('close')}
                    onSubmit={handleAddSubmit}
                    fields={columnsAdding}
                  />
                )
            }
            {
              EditPopupRow
                ? (
                  <EditPopupRow
                    row={rows[editIndex] as RowI | undefined}
                    isOpen={isPopup.edit}
                    onClose={handlePopup('close')}
                    onSubmit={handleEditSubmit}
                    title={editRowTitle || 'Edit row'}
                    basedOnType={basedOnType || ''}
                  />
                )
                : (
                  <PopupRow
                    row={rows[editIndex] as RowI | undefined}
                    catalogs={catalogs}
                    isOpen={isPopup.edit}
                    title={editRowTitle || 'Edit row'}
                    basedOnType={basedOnType || ''}
                    onClose={handlePopup('close')}
                    onSubmit={handleEditSubmit}
                    fields={columnsEditing}
                    onOpenClone={handleCloneSubmit}
                    isClone={isClone}
                    isCopyValues={isCopyValues}
                  />
                )
            }
            <PopupRow
              row={{ ...rows[editIndex], name: 'Last Name' } as RowI | undefined}
              catalogs={catalogs}
              isOpen={isPopup.clone}
              title={titleAddRow || 'Add row'}
              onClose={handlePopup('close')}
              onSubmit={handleAddSubmit}
              fields={columnsAdding}
            />
            <PopupRemoveRow
              title="Remove row"
              isOpen={isPopup.remove}
              onClose={handlePopup('close')}
              onSubmit={handleRemoveSubmit}
            />
          </>
        )
      }
    </Paper>
  );

  function getEditTitle(row: RowI | undefined, title: string | undefined) {
    if (row) {
      if (row.name) {
        return `${title}: ${row.name}`;
      }
      if (row.id) {
        return `${title}: ${row.id}`;
      }
    }
    return title;
  }

  function handleContentComponent({ row }: {row: GroupRow}) {
    return (<span>{row.value}</span>);
  }

  function handleSummaryItemComponent(props: TableSummaryRow.ItemProps) {
    const value = Number.isInteger(Number(props?.value)) ? props?.value : String(props?.value?.toFixed(2));
    return (
      <span className={clsx(classes.rowGroup, classes.summaryRow)}>{value}</span>
    );
  }

  function handleRowComponent(props: Table.DataRowProps) {
    const { row } = props;
    return <Table.Row {...props} className={clsx(onHandle ? onHandle(row) : '', { [classes.blinking]: row.blinking })} />;
  }

  function handleHeaderCellComponent(props: TableHeaderRow.CellProps & {className?: string}) {
    const { className, column } = props;

    if (isFilter) {
      const {
        filteringTableEnabled, filteringType, filteringAdditional, columnName, title,
      } = columnsByKey[column.name];
      const filter = filters?.find((item) => item.columnName === columnName);
      const catalog = catalogs && filteringType === TypeFilterEnum.SELECT
        ? catalogs[filteringAdditional.catalog] : null;

      return (
        <CellHeader
          {...props}
          className={className}
          type={filteringTableEnabled ? filteringType : 'none'}
          additional={filteringAdditional}
          columnname={columnName}
          value={filter?.value}
          initOperation={filter?.operation}
          title={title}
          catalog={catalog}
          functionfilter={{ handleFilter }}
        />
      );
    }

    return <TableHeaderRow.Cell {...props} className={clsx(className, classes.header)} />;
  }

  function handleHiddenColumns(hiddenColumnNames: string[]) {
    // const excludeHidden = ['edrAdvFeed', 'roiAdvFeed', 'agencyFee', 'roiPubFeed', 'edrPubFeed', 'edrRev', 'netRoiPub', 'netRoiAdv'];
    // const difference = hiddenColumnNames.filter((item) => !excludeHidden.includes(item));
    const configTable = {
      ...JSON.parse(getItem(STORAGE_KEY_HIDDEN_TABLE) || (userSettingsData || 'null')),
      [nameTable]: hiddenColumnNames,
    };
    saveItem(STORAGE_KEY_HIDDEN_TABLE, JSON.stringify(configTable));
    updateUserSettings({
      variables: {
        node: JSON.stringify(configTable),
        settingsName: STORAGE_KEY_HIDDEN_TABLE,
        userEmail: user?.email,
      },
    }).then();
    setHiddenColumns(hiddenColumnNames);
  }

  function handleCloneSubmit() {
    handlePopup('clone')();
  }

  function handleEditSubmit(values: RowI) {
    return handleChangeCommit({ changed: { [editIndex]: values } });
  }

  function handleColumnWidthsChange(nextColumnWidths: Array<TableColumnWidthInfo>) {
    const configTable = {
      ...JSON.parse(getItem(STORAGE_KEY_CONFIG_TABLE) || 'null'),
      [nameTable]: nextColumnWidths.map((item) => ({
        columnName: item.columnName,
        width: item.width,
      })),
    };
    saveItem(STORAGE_KEY_CONFIG_TABLE, JSON.stringify(configTable));
    setColumnWidths(nextColumnWidths);
  }

  function handleCellComponent(props: Table.DataCellProps & {className?: string}) {
    const { column, className } = props;
    const {
      filteringType, filteringAdditional,
    } = columnsByKey[column.name];
    const catalog = catalogs && filteringType === TypeFilterEnum.SELECT
      ? catalogs[filteringAdditional?.catalog] : null;
    return (
      <Cell
        {...props}
        type={columnsByKey[column.name].type}
        title={columnsByKey[column.name].customTitle}
        catalog={catalog}
        // table={nameTable}
        onClick={onAction}
        handle={{ remove: handleRemoveRow, edit: handleRemoveEdit }}
        className={clsx(className, classes.row, customCellCss)}
      />
    );
  }

  function handleRemoveEdit(_: React.ChangeEvent<{}>, id: number) {
    setEditIndex(id);
    handlePopup('edit')();
  }

  function handleAddSubmit(values: RowI) {
    return handleChangeCommit({ added: [values] });
  }

  function handleRemoveSubmit() {
    return handleChangeCommit({ deleted: deleteList });
  }

  function handlePopup(action: string) {
    return () => {
      switch (action) {
        case 'add':
        case 'remove':
        case 'edit':
          setPopup({
            ...isPopup,
            [action]: true,
          });
          break;
        case 'clone':
          setPopup({
            [action]: true,
            add: false,
            edit: false,
            remove: false,
          });
          break;
        default:
          setPopup({
            ...isPopup,
            add: false,
            edit: false,
            remove: false,
            clone: false,
          });
      }
    };
  }

  function handleRemoveRow(_: React.ChangeEvent<{}>, id: number) {
    handleChangeCommit({ deleted: [id] });
  }

  function handleChangePage(page: number) {
    const skip = params && params.take ? params.take * page : 0;
    setCurrentPage(page);
    if (onChangeParams) {
      onChangeParams({ ...params, skip });
    }
  }

  function handlePageSizeChange(pageSize: number) {
    if (onChangeParams) {
      onChangeParams({ ...params, take: pageSize });
    }
  }

  function handleChangeSorting(sort: Sorting[]) {
    if (onChangeParams) {
      onChangeParams({ ...params, sort });
    }
  }

  async function handleChangeCommit({ added, changed, deleted }: ChangeSet): Promise<ParamsMutationResponseI> {
    const variables: ParamsMutationI = { node: undefined, type: undefined };
    if (deleted && !deleted[-1]) {
      if (!isPopup.remove) {
        handlePopup('remove')();
        setDeleteList(deleted as number[]);
      } else {
        setDeleteList([-1]);
        variables.node = { ...rows[deleted[0]] };
        variables.type = ActionsEnum.REMOVE;
      }
    }

    if (changed) {
      const fields = Object.values(changed);
      variables.node = {
        ...(rows
          .filter((_: RowI, i: number) => changed[i])
          .map((row: RowI, i: number) => ({ ...row, ...fields[i] }))
          .pop()),
      };
      variables.type = ActionsEnum.EDIT;
      if (nameTable === 'export') {
        setEditIndex(-1);
      }
    }

    if (added) {
      variables.node = { ...added[0], id: -1 };
      variables.type = ActionsEnum.ADD;
    }

    if (variables.node
        && ((variables.node as Record<string, number>).id || Array.isArray(variables.node))
        && variables.type) {
      delete (variables.node as Record<string, string>).__typename;
      const result = await (onMutation as ((variables: ParamsMutationI)
        => Promise<ParamsMutationResponseI>))(variables);
      return result;
    }

    return {
      success: false,
      message: 'No action',
    };
  }

  // TODO: Repeat code. Matrix
  function downHandler({ key }: any) {
    if (['ArrowRight', 'ArrowDown', 'ArrowLeft', 'ArrowUp'].includes(key)) {
      const table = (refGridTable.current?.children[1]?.childNodes[1] as any);
      const positionScroll = { top: table.scrollTop, left: table.scrollLeft };
      if (key === 'ArrowRight') {
        positionScroll.left += 50;
      } else if (key === 'ArrowDown') {
        positionScroll.top += 50;
      } else if (key === 'ArrowLeft') {
        positionScroll.left -= 50;
      } else if (key === 'ArrowUp') {
        positionScroll.top -= 50;
      }
      table.scrollTo(positionScroll);
    }
  }
};

export default TableUniversal;
