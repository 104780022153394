import { colorDecrease, colorIncrease, colorYellow } from '@/config/color';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => (
  {
    container: {
      height: '32px',
      borderRadius: 0,
      width: '30px',
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
      position: 'relative',
      transform: 'rotate(-90deg)',
    },
    fillPercent: {
      borderRadius: 0,
      backgroundColor: colorIncrease,
      position: 'absolute',
      height: '100%',
      top: 0,
      left: 0,
    },
    yellowFillPercent: {
      borderRadius: 0,
      backgroundColor: colorYellow,
      position: 'absolute',
      height: '50%',
      top: 0,
      left: 0,
    },
    redLine: {
      position: 'absolute',
      background: colorDecrease,
      height: '44px',
      width: '2px',
      top: '-6px',
      left: 0,
    },
  }
));

export default useStyles;
