import React, { FC } from 'react';
import useStyles from './styles';

interface IProps {
  value: number;
  redLineValue: number;
  percentEom: number;
}

const ColumnProgressUI: FC<IProps> = ({
  value,
  redLineValue,
  percentEom,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.fillPercent} style={{ width: `${value}%` }} />
      <div className={classes.yellowFillPercent} style={{ width: `${percentEom}%` }} />
      <div className={classes.redLine} style={{ left: `${redLineValue}%` }} />
    </div>
  );
};

export default ColumnProgressUI;
