/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Filter } from '@devexpress/dx-react-grid';
import differenceInMonths from 'date-fns/differenceInMonths';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import startOfWeek from 'date-fns/startOfWeek';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import { ValueType } from 'rsuite/lib/DateRangePicker/DateRangePicker';
import getDay from 'date-fns/getDay';
import getWeekOfMonth from 'date-fns/getWeekOfMonth';
import setDay from 'date-fns/setDay';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { DateCompareE, TypeDateE } from './types';

export const isMoreMount = (filterByDate: Filter[]) => {
  const date = filterByDate.find((item) => item.columnName === 'date');
  if (!date) {
    return false;
  }
  const betweenDate = date.value?.split(' to ');
  if (!betweenDate) {
    return false;
  }
  const fromDate = parse(betweenDate[0] || '', 'MM/dd/yyyy', new Date());
  const tillDate = parse(betweenDate[1] || '', 'MM/dd/yyyy', new Date());
  return differenceInMonths(tillDate, fromDate) > 0;
};

export const isValidDate = (date: Date) => date.toString() !== 'Invalid Date';

export const getDaysBetween = (filterByDate: Filter[]) => {
  const date = filterByDate.find((item) => item.columnName === 'date');
  if (date) {
    const betweenDate = date.value?.split(' to ');
    if (betweenDate) {
      const fromDate = parse(betweenDate[0] || '', 'MM/dd/yyyy', new Date());
      const tillDate = parse(betweenDate[1] || '', 'MM/dd/yyyy', new Date());
      const differenceInTime = tillDate.getTime() - fromDate.getTime();

      return differenceInTime / (1000 * 3600 * 24);
    }
  }
  return 0;
};

export const getInterval = (value: number) => {
  if (value === 0) {
    return 'hour';
  }
  if (value >= 720) {
    return 'year';
  }
  if (value < 720) {
    if (value >= 360) {
      return 'quarter';
    }
    if (value >= 180) {
      return 'month';
    }
    if (value >= 60) {
      return 'week';
    }
  }
  return '';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const compareDate = (a: any, b: any) => {
  const dateA = new Date(a);
  const dateB = new Date(b);
  if (isValidDate(dateA) && isValidDate(dateB)) {
    return dateA.getTime() - dateB.getTime();
  }
  // eslint-disable-next-line no-nested-ternary
  return (a === b) ? 0 : ((a < b) ? -1 : 1);
};

export const getDateFilterFormat = (from: Date, till: Date) => `${format(from, 'MM/dd/yyyy')} to ${format(till, 'MM/dd/yyyy')}`;

export const getFilterByDate = (from: Date, till: Date, columnName = 'date') => ({
  columnName,
  value: getDateFilterFormat(from, till),
  operation: 'undefined',
});

export const getInFilterToday = () => {
  const today = new Date();
  return getFilterByDate(today, today);
};

export const getInFilterYesterday = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return getFilterByDate(yesterday, yesterday);
};

export const getInFilterLastNDays = (n: number) => {
  const today = new Date();
  const week = new Date();
  week.setDate(today.getDate() - n);
  return getFilterByDate(week, today);
};

export const getInFilterWeek = () => {
  const today = new Date();
  const week = new Date();
  week.setDate(week.getDate() - 6);
  return getFilterByDate(week, today);
};

export const getInFilterWeekYesterday = () => {
  const today = new Date();
  today.setDate(today.getDate() - 1);
  const week = new Date();
  week.setDate(week.getDate() - 7);
  return getFilterByDate(week, today);
};

export const getInFilterLastWeek = () => {
  const week = new Date();
  week.setDate(week.getDate() - 7);

  const startWeek = startOfWeek(week);
  const endWeek = endOfWeek(week);
  return getFilterByDate(startWeek, endWeek);
};

export const getInFilterLastMonth = () => {
  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  lastMonth.setDate(1);
  return getFilterByDate(lastMonth, lastDayOfMonth(lastMonth));
};

export const getFilterByDateType = (type: TypeDateE): Filter => {
  switch (type) {
    case TypeDateE.TODAY:
      return getInFilterToday();
    case TypeDateE.YESTERDAY:
      return getInFilterYesterday();
    case TypeDateE.WEEK:
      return getInFilterWeek();
    case TypeDateE.LAST_WEEK:
      return getInFilterLastWeek();
    case TypeDateE.LAST_7_DAYS:
      return getInFilterLastNDays(7);
    case TypeDateE.LAST_30_DAYS:
      return getInFilterLastNDays(30);
    case TypeDateE.LAST_90_DAYS:
      return getInFilterLastNDays(90);
    default: // TypeDateE.LAST_MOUNTH
      return getInFilterLastMonth();
  }
};

export const parserBetweenDates = (date: string) => {
  if (date.indexOf(' to ') !== -1) {
    const betweenDate = date.split(' to ');
    const fromDate = parse(betweenDate[0] || '', 'MM/dd/yyyy', new Date());
    const tillDate = parse(betweenDate[1] || '', 'MM/dd/yyyy', new Date());
    if (fromDate.toString() === 'Invalid Date' || tillDate.toString() === 'Invalid Date') {
      return [];
    }
    return [fromDate, tillDate];
  }
  return [];
};

export const getRangeDate = (filterByDate: Filter[]): ValueType => {
  const filterDate = filterByDate.find((item: Filter) => item.columnName === 'date');
  return parserBetweenDates(filterDate?.value || '') as ValueType;
};

export const getYesterday = (day: Date) => {
  day.setDate(day.getDate() - 1);
  return day;
};

export const getLastWeek = (day: Date) => {
  day.setDate(day.getDate() - 7);
  return day;
};

export const getLastMonth = (day: Date) => {
  day.setMonth(day.getMonth() - 1);
  return day;
};

export const getLastNDays = (day: Date, n: number) => {
  day.setDate(day.getDate() - n);
  return day;
};

export const getLastNMonthsArr = (n: number, offset = 1) => {
  const months = [];
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - offset);

  for (let i = 1; i < n + 1; i++) {
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.toLocaleString('en-En', { month: 'long' });

    months.push(`${currentMonth} ${currentYear}`);

    currentDate.setMonth(currentDate.getMonth() - 1);
  }

  return months;
};

export const getFilterByCompareDateType = (type: string, date: string): string | undefined => {
  const betweenDate = parserBetweenDates(date);
  if (!betweenDate.length) {
    return undefined;
  }
  if (type === 'dayBefore') {
    return getDateFilterFormat(getYesterday(betweenDate[0]), getYesterday(betweenDate[1]));
  }
  if (type === 'lastWeek') {
    return getDateFilterFormat(getLastWeek(betweenDate[0]), getLastWeek(betweenDate[1]));
  }
  if (type === 'lastMonth') {
    return getDateFilterFormat(getLastMonth(betweenDate[0]), getLastMonth(betweenDate[1]));
  }
  return undefined;
};

export const getCompareDateInFormatDate = (type: DateCompareE, date: ValueType): ValueType => {
  switch (type) {
    case DateCompareE.DAY:
      return [getYesterday(date[0] as Date), getYesterday(date[1] as Date)];
    case DateCompareE.WEEK:
      return [getLastWeek(date[0] as Date), getLastWeek(date[1] as Date)];
    case DateCompareE.MONTH:
      return [getLastMonth(date[0] as Date), getLastMonth(date[1] as Date)];
    case DateCompareE.MONTH_DOW:

      const dayOfweek = getDay(date[0] as Date);
      const weekNumber = getWeekOfMonth(date[0] as Date);

      const lastMonth = new Date(date[0] as Date);
      lastMonth.setDate(1);
      lastMonth.setMonth(lastMonth.getMonth() - 1);

      let monthDOW;
      for (let i = weekNumber; i > 0; i--) {
        const lastMonth0 = new Date(date[0] as Date);
        lastMonth0.setDate(1);
        lastMonth0.setMonth(lastMonth0.getMonth() - 1);
        lastMonth0.setDate((i - 1 > 0) ? (i - 1) * 7 : 1);

        if (setDay(lastMonth0, dayOfweek).getMonth() === lastMonth.getMonth()) {
          monthDOW = setDay(lastMonth0, dayOfweek);
          break;
        }
      }
      if (!monthDOW) {
        for (let i = (weekNumber + 1); i <= 3; i++) {
          const lastMonth0 = new Date(date[0] as Date);
          lastMonth0.setDate(1);
          lastMonth0.setMonth(lastMonth0.getMonth() - 1);
          lastMonth0.setDate((i - 1 > 0) ? (i - 1) * 7 : 1);

          if (setDay(lastMonth0, dayOfweek).getMonth() === lastMonth.getMonth()) {
            monthDOW = setDay(lastMonth0, dayOfweek);
            break;
          }
        }
      }

      const delta = differenceInCalendarDays(date[1] as Date, date[0] as Date);

      const endMonthDOW = new Date(monthDOW as Date);
      endMonthDOW.setDate(endMonthDOW.getDate() + delta);

      return [monthDOW, endMonthDOW];
    case DateCompareE.CUSTOM:
      return date;
    default:
      return [undefined, undefined];
  }
};
