/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-case-declarations */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { omit, isNil } from 'lodash';
import { IconButton } from '@/ui';
import format from 'date-fns/format';
import {
  mdiDelete as DeleteIcon,
  mdiPencil as EditIcon,
  mdiArrowDown as ArrowDownIcon,
  mdiArrowUp as ArrowUpIcon,
  mdiXml as XmlIcon,
  mdiApi as ApiIcon,
  mdiAvTimer as RealtimeIcon,
  mdiChartBoxOutline as ReportIcon,
  mdiWebhook as TrafficIcon,
  mdiEmail as EmailIcon,
  mdiBriefcaseClockOutline as JobsIcon,
} from '@mdi/js';
import { ReactComponent as TradeIcon } from '@/theme/icons/trade.svg';
import { ReactComponent as ColumnIcon } from '@/theme/icons/column.svg';
import IconUI from '@mdi/react';
import clsx from 'clsx';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import StarRatingComponent from 'react-star-rating-component';
import { getGradient } from '@/utils/common';
import ReactTooltip from 'react-tooltip';
import NumberObj from '@/utils/number';
import { TypeNumberEnum } from '@/utils/number/types';
import { ColumnProgressUI } from '@/ui/ColumnProgressUI';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Rating from 'react-rating';
import {
  Link, Select, MenuItem,
} from '@material-ui/core';
import { isValidDate } from '@/utils/date';
import Button from '@material-ui/core/Button';
import {
  getPerformanceColor,
  getRoiPercentColor,
  getTqfRoiPercentPColor,
  getVolumeColor,
} from '@/app/Trade/Matrix/utils';
import { ThemeContext } from '@/providers/ThemeProvider';
import { ThemeEnum } from '@/providers/types';
import { LinearSimpleProgressUI } from '@/ui/LinearSimpleProgressUI';
import { TypeFieldEnum } from '../types';
import useStyles from '../styles';

const getCurrencyGradientColor = (value: number) => {
  if (value <= 1) {
    if (value <= 1 && value > 0.5) {
      return 'yellow';
    } if (value <= 0.5 && value > 0) {
      return 'pink';
    }
    return 'decreasing';
  }
  if (value > 1) {
    if (value > 1 && value <= 2) {
      return 'lightGreen';
    } if (value > 2 && value <= 5) {
      return 'darkerGreen';
    }
    return 'lightGreen';
  }
  return '';
};

const getIntentColor = (value: number) => {
  if (value > 3 && value <= 6) {
    return 'yellow';
  }
  if (value > 7 && value <= 14) {
    return 'increasing';
  }
  if (value > 15) {
    return 'brightGreen';
  }
  return 'decreasing';
};

const getCurrencyDecreaseColor = (value: number) => {
  if (value < 0) {
    return 'decreasing';
  }
  return '';
};

const getCurrencyCPACColor = (value: number) => {
  if (value === 0) {
    return 'decreasing'; // red
  }
  if (value < 1) {
    return 'increasing'; // green
  }
  if (value >= 1 && value < 5) {
    return 'yellow';
  }
  if (value >= 5 && value < 10) {
    return 'orange';
  }

  return 'decreasing'; // red
};

const getWebsiteStatusColor = (value: string) => {
  switch (value) {
    case 'pending':
      return 'yellow';
    case 'active':
      return 'darkerGreen';
    case 'archived': // used for websites
      return 'decreasing';
    default:
      return '';
  }
};

const getCellIcon = (value: string) => {
  switch (value) {
    case 'showInTqf':
      return TrafficIcon;
    case 'showJobs':
      return JobsIcon;
    case 'advEmail':
    case 'pubEmail':
      return EmailIcon;
    default:
      return ReportIcon;
  }
};

const getActiveCell = (column: string, row: any) => column === 'showInTqf' && (row.tqfList && row.tqfList.length);

const getFeedStatusColor = (value: string) => {
  switch (value) {
    case 'testing':
    case 'pending': // used for websites
      return 'yellow';
    case 'pilot':
      return 'lightBlue';
    case 'live':
    case 'active': // used for websites
      return 'darkerGreen';
    case 'optimize':
      return 'increasing';
    case 'pause':
      return 'pink';
    case 'closed':
    case 'archived': // used for websites
      return 'decreasing';
    default:
      return '';
  }
};

const getSelectCustomColor = (columnName: string, value: string) => {
  switch (columnName) {
    case 'status':
      return getFeedStatusColor(value);
    default:
      return '';
  }
};

const getNAColor = (value: string) => {
  switch (value) {
    case '1-High':
      return 'increasing';
    case '2-Med':
      return 'yellow';
    case '3-Low':
      return 'decreasing';
    default:
      return '';
  }
};

const fillBarPercent = (value: number, mtdRevenue: number) => {
  if (value > 0) {
    return value > mtdRevenue ? Number(mtdRevenue / value) * 100 : 100;
  }

  return 0;
};

// const getMTDBg = (column: string, row: any) => (((Number(row.budget) / Number(row[column])) * 100) >= 15);

const lessThanHoursAgo = (dateValue: number, hours: number) => {
  const date = new Date(dateValue);
  const dateNow = new Date();
  const utc = dateNow.getTime() + (dateNow.getTimezoneOffset() * 60000);
  const newDate = new Date(utc + (3600000 * -5));
  return ((newDate.getTime() - date.getTime()) > hours);
};

const Cell = (
  props: Table.DataCellProps & {
    type: string;
    // table: string;
    title?: string;
    catalog: Record<string, string>[];
    onClick?: Function;
    handle: Record<string, Function>;
    className?: string;
 },
) => {
  const {
    type, title, value, column, catalog, onClick = () => {}, handle, tableRow, className,
  } = props;
  const classes = useStyles();
  const { tooltipFraud } = useStyles();
  const preparedProps = omit({ ...props }, ['onClick']);
  const { themeName } = useContext(ThemeContext);

  // const [statusCheckbox, setStatusCheckbox] = useState({ [table]: {} });
  if (isNil(value)
    && ![TypeFieldEnum.DELETE, TypeFieldEnum.BUTTON,
      TypeFieldEnum.EDIT, TypeFieldEnum.BAR,
      TypeFieldEnum.BORDER, TypeFieldEnum.STAR,
      TypeFieldEnum.CHECKBOX, TypeFieldEnum.CELL_CHECKBOX].includes(type as TypeFieldEnum)) {
    return <Table.Cell {...preparedProps} name={column.name} />;
  }

  switch (type) {
    case TypeFieldEnum.NUMBER:
      const number = NumberObj.create(value);
      return (
        <Table.Cell {...preparedProps} value={number.toString()} name={column.name} />
      );
    case TypeFieldEnum.BOOLEAN:
      return (
        <Table.Cell {...preparedProps} value={value ? 'Yes' : 'No'} name={column.name} />
      );
    case TypeFieldEnum.SELECT_EDIT:
      return (
        <Table.Cell {...preparedProps} className={classes.cellController}>
          <Select
            id={value}
            value={value}
            onChange={(e) => {
              onClick(
                e, column.name, e.target.value, tableRow.row, TypeFieldEnum.SELECT_EDIT,
              );
            }}
            className={clsx(classes.select, classes[getSelectCustomColor(column.name, value)])}
          >
            {
              !!catalog && catalog.map((menuItem) => (
                <MenuItem key={`${menuItem.key}-menuItem`} value={menuItem.key}>{menuItem.name}</MenuItem>
              ))
            }
          </Select>
        </Table.Cell>
      );
    case TypeFieldEnum.SWITCH:
      return (
        <Table.Cell {...preparedProps} className={classes.cellController}>
          <Switch
            size="small"
            checked={!!value}
            classes={{
              switchBase: classes.colorSwitchBase,
              track: classes.colorSwitchTrack,
            }}
            onChange={(e: React.ChangeEvent<{}>) => onClick(
              e, column.name, !value, tableRow.row, TypeFieldEnum.SWITCH,
            )}
          />
        </Table.Cell>
      );
    case TypeFieldEnum.LINK:
      return (
        <Table.Cell
          {...preparedProps}
          name={column.name}
          onClick={(e: React.ChangeEvent<{}>) => onClick(e, column.name, value, tableRow.row, TypeFieldEnum.LINK)}
          className={clsx(className, classes.cellLink)}
        />
      );
    case TypeFieldEnum.SHOW_IN:
      return (
        <Table.Cell
          {...preparedProps}
          name={column.name}
          onClick={(e: React.ChangeEvent<{}>) => onClick(e, column.name, value, tableRow.row, TypeFieldEnum.SHOW_IN)}
          className={clsx(className, classes.cellLink)}
        />
      );
    case TypeFieldEnum.SHOW_IN_WITH_ICON:
      const isActive = getActiveCell(column.name, tableRow.row);
      return (
        <Table.Cell {...preparedProps} className={classes.cellController}>
          {(column.name === 'editMatrix') ? (
            <IconButton
              onClick={(e: React.ChangeEvent<{}>) => onClick(
                e, column.name, value, tableRow.row, TypeFieldEnum.SHOW_IN_WITH_ICON,
              )}
              title={value}
            >
              <TradeIcon className={clsx(classes.icon)} />
            </IconButton>
          ) : (
            <IconButton
              path={getCellIcon(column.name)}
              onClick={(e: React.ChangeEvent<{}>) => onClick(
                e, column.name, value, tableRow.row, TypeFieldEnum.SHOW_IN_WITH_ICON,
              )}
              className={clsx({ [classes.increasing]: isActive })}
              title={value}
            />
          )}
        </Table.Cell>
      );
    case TypeFieldEnum.SHOW_IN_DECREASING:
      return (
        <Table.Cell
          {...preparedProps}
          name={column.name}
          onClick={(e: React.ChangeEvent<{}>) => onClick(
            e, column.name, value, tableRow.row, TypeFieldEnum.SHOW_IN_DECREASING,
          )}
          className={clsx(className, classes.cellLink, { [classes.decreasing]: tableRow.row.decreasing })}
        />
      );
    case TypeFieldEnum.CURRENCY:
      const currency = NumberObj.create(value, TypeNumberEnum.CURRENCY);
      return (
        <Table.Cell
          {...preparedProps}
          value={currency.toString()}
          name={column.name}
        />
      );
    case TypeFieldEnum.COMPARE_BG_WITH_CURRENCY:
      const bgCurrency = NumberObj.create(value, TypeNumberEnum.CURRENCY);
      return (
        <Table.Cell
          {...preparedProps}
          value={bgCurrency.toString()}
          className={clsx(classes.cell,
            { [classes.gray]: themeName === ThemeEnum.DARK },
            { [classes.lightGray]: themeName === ThemeEnum.LIGHT })}
          name={column.name}
        />
      );
    case TypeFieldEnum.DELTA_COMPARE_BG_WITH_CURRENCY:
      const bgCurrency2 = NumberObj.create(value, TypeNumberEnum.CURRENCY);
      return (
        <Table.Cell
          {...preparedProps}
          value={bgCurrency2.toString()}
          className={clsx(classes.cell,
            { [classes.darkGray]: themeName === ThemeEnum.DARK },
            { [classes.darkerGray]: themeName === ThemeEnum.LIGHT })}
          name={column.name}
        />
      );
    case TypeFieldEnum.PERCENT:
      const percent = NumberObj.create(value, TypeNumberEnum.PERCENT);
      return <Table.Cell {...preparedProps} value={percent.toString()} name={column.name} />;
    case TypeFieldEnum.DELETE:
      return (
        <Table.Cell {...preparedProps} className={classes.cellController}>
          <IconButton path={DeleteIcon} onClick={(e) => handle.remove(e, tableRow.rowId as number)} title="Delete row" />
        </Table.Cell>
      );
    case TypeFieldEnum.EDIT:
      return (
        <Table.Cell {...preparedProps} className={classes.cellController}>
          <IconButton path={EditIcon} onClick={(e) => handle.edit(e, tableRow.rowId as number)} title="Edit row" />
        </Table.Cell>
      );
    case TypeFieldEnum.STAR:
      return (
        <Table.Cell {...preparedProps} className={classes.cellController}>
          <section className={clsx(classes.containerFlex, classes.ml8)}>
            <StarRatingComponent
              name={column.name}
              starCount={5}
              onStarClick={(nextValue: number) => onClick(
                null, column.name, nextValue, tableRow.row, TypeFieldEnum.STAR,
              )}
              value={value}
              emptyStarColor="#9c9da2"
            />
          </section>
        </Table.Cell>
      );
    case TypeFieldEnum.PERCENT_WITH_BORDER:
      const percentWithBorder = NumberObj.create(value, TypeNumberEnum.PERCENT);
      return (
        <Table.Cell
          {...preparedProps}
          style={{ borderRight: '1px solid rgba(81, 81, 81, 1)' }}
          value={percentWithBorder.toString()}
          name={column.name}
        />
      );
    case TypeFieldEnum.NUMBER_WITH_BORDER:
      const numberWithBorder = NumberObj.create(value);
      return (
        <Table.Cell
          {...preparedProps}
          style={{ borderRight: '1px solid rgba(81, 81, 81, 1)' }}
          value={numberWithBorder.toString()}
          name={column.name}
        />
      );
    case TypeFieldEnum.CURRENCY_WITH_BORDER:
      const currencyWithBorder = NumberObj.create(value, TypeNumberEnum.CURRENCY);
      return (
        <Table.Cell
          {...preparedProps}
          style={{ borderRight: '1px solid rgba(81, 81, 81, 1)' }}
          value={currencyWithBorder.toString()}
          name={column.name}
        />
      );
    case TypeFieldEnum.QUALITY_STAR:
      return (
        <Table.Cell {...preparedProps} className={classes.cellController}>
          <section className={clsx(classes.containerFlex, classes.ml8)} data-tip="Quality is 1 square for 0.5% Conversion to Apply">
            <Rating
              // onChange={(nextValue: number) => onClick(
              //   null, column.name, nextValue, tableRow.row, TypeFieldEnum.STAR,
              // )}
              readonly
              initialRating={value}
              fractions={2}
              emptySymbol={<span className={classes.ratingEmpty}>■</span>}
              fullSymbol={[
                <span key="1" className={classes.ratingFull} style={{ backgroundImage: 'linear-gradient(to right, rgb(255, 104, 89), #f48f3c)' }}>■</span>,
                <span key="2" className={classes.ratingFull} style={{ backgroundImage: 'linear-gradient(to right, #f48f3c, #edbd32)' }}>■</span>,
                <span key="3" className={classes.ratingFull} style={{ backgroundImage: 'linear-gradient(to right, #edbd32, #eaea2d)' }}>■</span>,
                <span key="4" className={classes.ratingFull} style={{ backgroundImage: 'linear-gradient(to right, #eaea2d, #85cd36)' }}>■</span>,
                <span key="5" className={classes.ratingFull} style={{ backgroundImage: 'linear-gradient(to right, #85cd36, rgb(5, 173, 120)' }}>■</span>,
              ]}
            />
          </section>
          <ReactTooltip />
        </Table.Cell>
      );
    case TypeFieldEnum.BAR:
      return (
        <Table.Cell {...preparedProps} className={classes.cellController}>
          <section className={clsx(classes.containerFlex, classes.ml6)}>
            <StarRatingComponent
              name={column.name}
              starCount={5}
              value={value}
              emptyStarColor="#9c9da2"
              renderStarIcon={() => <span><ColumnIcon /></span>}
            />
          </section>
        </Table.Cell>
      );
    case TypeFieldEnum.VOLUME_BAR:
      return (
        <Table.Cell {...preparedProps} className={classes.cellController}>
          <section className={clsx(classes.containerFlex, classes.ml6)} data-tip="Volume is 1 bar = 1,000 clicks/day">
            <Rating
              // onChange={(nextValue: number) => onClick(
              //   null, column.name, nextValue, tableRow.row, TypeFieldEnum.STAR,
              // )}
              readonly
              initialRating={value}
              fractions={2}
              emptySymbol={[
                <div key="1" className={classes.volumeBar} style={{ height: 4 }} />,
                <div key="2" className={classes.volumeBar} style={{ height: 8 }} />,
                <div key="3" className={classes.volumeBar} style={{ height: 12 }} />,
                <div key="4" className={classes.volumeBar} style={{ height: 16 }} />,
                <div key="5" className={classes.volumeBar} style={{ height: 20 }} />,
              ]}
              fullSymbol={[
                <div key="1" className={classes.volumeBarFull} style={{ height: 4 }} />,
                <div key="2" className={classes.volumeBarFull} style={{ height: 8 }} />,
                <div key="3" className={classes.volumeBarFull} style={{ height: 12 }} />,
                <div key="4" className={classes.volumeBarFull} style={{ height: 16 }} />,
                <div key="5" className={classes.volumeBarFull} style={{ height: 20 }} />,
              ]}
            />
          </section>
          <ReactTooltip />
        </Table.Cell>
      );
    case TypeFieldEnum.CELL_EDIT:
      return (
        <Table.Cell
          {...preparedProps}
          name={column.name}
          onClick={(e: React.ChangeEvent<{}>) => handle.edit(e, tableRow.rowId as number)}
          className={clsx(className, classes.cellLink)}
        />
      );

    case TypeFieldEnum.CELL_EDIT_DECREASING:
      return (
        <Table.Cell
          {...preparedProps}
          name={column.name}
          onClick={(e: React.ChangeEvent<{}>) => handle.edit(e, tableRow.rowId as number)}
          className={clsx(className, classes.cellLink, { [classes.decreasing]: tableRow.row.decreasing })}
        />
      );
    case TypeFieldEnum.CUSTOM_CELL_EDIT:
      return (
        <>
          {value ? (
            <Table.Cell
              {...preparedProps}
              name={column.name}
              onClick={(e: React.ChangeEvent<{}>) => onClick(
                e, column.name, value, tableRow.row, TypeFieldEnum.CUSTOM_CELL_EDIT,
              )}
              className={clsx(className, classes.cellLink)}
            />
          ) : (
            <Table.Cell
              {...preparedProps}
              name={column.name}
              className={clsx(className)}
            />
          )}
        </>
      );
    case TypeFieldEnum.CUSTOM_CELL_EDIT_DECREASING:
      return (
        <Table.Cell
          {...preparedProps}
          name={column.name}
          onClick={(e: React.ChangeEvent<{}>) => onClick(
            e, column.name, value, tableRow.row, TypeFieldEnum.CUSTOM_CELL_EDIT_DECREASING,
          )}
          className={clsx(className, classes.cellLink, { [classes.decreasing]: tableRow.row.decreasing })}
        />
      );
    case TypeFieldEnum.CELL_MULTI_EDIT:
      return (
        <Table.Cell
          {...preparedProps}
          name={column.name}
        >
          {
            value.map((item: {id: string; name: string}, index: number) => (
              <>
                {`${index ? ', ' : ''}`}
                <Link
                  key={`${item.id}-${tableRow.row.id}`}
                  className={clsx(className, classes.cellLink)}
                  onClick={(e: React.ChangeEvent<{}>) => onClick(
                    e, column.name, item.id, tableRow.row, TypeFieldEnum.CELL_MULTI_EDIT,
                  )}
                >
                  {item.name}
                </Link>
              </>
            ))
          }
        </Table.Cell>
      );
    case TypeFieldEnum.COLOR_SHORT:
      const number1 = NumberObj.create(value);
      return (
        <Table.Cell
          {...preparedProps}
          value={number1.toString()}
          name={column.name}
          className={clsx(className, { [classes.decreasing]: number1.value < 0 })}
        />
      );
    case TypeFieldEnum.COLOR_SHORT_CURRENCY:
      const currency1 = NumberObj.create(value, TypeNumberEnum.CURRENCY);
      return (
        <Table.Cell
          {...preparedProps}
          value={currency1.toString()}
          name={column.name}
          className={clsx(className, { [classes.decreasing]: currency1.value < 0 })}
        />
      );
    case TypeFieldEnum.CURRENCY_CPAC_COLOR:
      const currencyCpac = NumberObj.create(value, TypeNumberEnum.CURRENCY);
      return (
        <Table.Cell
          {...preparedProps}
          value={currencyCpac.toString()}
          name={column.name}
          className={clsx(className, classes[getCurrencyCPACColor(value)])}
        />
      );
    case TypeFieldEnum.HTML_TEXT:
      const roiCurrency = NumberObj.create(value);
      return (
        <Table.Cell
          {...preparedProps}
          /* eslint-disable-next-line react/no-danger */
          value={<span dangerouslySetInnerHTML={{ __html: value }} />}
          className={clsx(classes.cell,
            { [classes.increasing]: (roiCurrency.value > -500 && roiCurrency.value < -150) },
            { [classes.decreasing]: roiCurrency.value < 0 })}
        />
      );
    case TypeFieldEnum.DECREASING_DATE:
      return (
        <Table.Cell
          {...preparedProps}
          name={column.name}
          className={clsx(className, { [classes.decreasing]: tableRow.row.decreasing })}
        />
      );
    case TypeFieldEnum.HTML_DATE_DECREASE:
      const compareDate = lessThanHoursAgo(value, 12000 * 60 * 60); // 12 hours
      return (
        <Table.Cell
          {...preparedProps}
          /* eslint-disable-next-line react/no-danger */
          value={<span dangerouslySetInnerHTML={{ __html: value }} />}
          className={clsx(classes.cell,
            { [classes.decreasing]: compareDate })}
        />
      );
    case TypeFieldEnum.ROI_PERCENT_LINK:
      const roiPercent = NumberObj.create(value, TypeNumberEnum.PERCENT);
      const boldRoiColor = Number(value) >= 45 && Number(value < 75);
      return (
        <Table.Cell
          {...preparedProps}
          value={roiPercent.toString()}
          style={{ color: getRoiPercentColor(Number(value)) }}
          className={clsx(classes.cell, classes.cellLink, { [classes.boldCell]: boldRoiColor })}
          onClick={
            (e: React.ChangeEvent<{}>) => onClick(
              e, column.name, value, tableRow.row, TypeFieldEnum.ROI_PERCENT_LINK,
            )
          }
        />
      );
    case TypeFieldEnum.PERFORMANCE_PERCENT_LINK:
      const performancePercentLink = NumberObj.create(value, TypeNumberEnum.PERCENT);
      const boldBlendColorLink = Number(value) >= 15 && Number(value < 25);
      return (
        <Table.Cell
          {...preparedProps}
          value={performancePercentLink.toString()}
          style={{ color: getPerformanceColor(Number(value)) }}
          className={clsx(classes.cell, classes.cellLink, { [classes.boldCell]: boldBlendColorLink })}
          onClick={
            (e: React.ChangeEvent<{}>) => onClick(
              e, column.name, value, tableRow.row, TypeFieldEnum.PERFORMANCE_PERCENT_LINK,
            )
          }
        />
      );
    case TypeFieldEnum.PERFORMANCE_PERCENT:
      const performancePercent = NumberObj.create(value, TypeNumberEnum.PERCENT);
      const boldBlendColor = Number(value) >= 15 && Number(value < 25);
      return (
        <Table.Cell
          {...preparedProps}
          value={performancePercent.toString()}
          style={{ color: getPerformanceColor(Number(value)) }}
          className={clsx(classes.cell, { [classes.boldCell]: boldBlendColor })}
        />
      );
    case TypeFieldEnum.TQF_ROI_PERCENT:
      const tqfROIPercent = NumberObj.create(value, TypeNumberEnum.PERCENT);
      return (
        <Table.Cell
          {...preparedProps}
          value={tqfROIPercent.toString()}
          style={{ color: getTqfRoiPercentPColor(Number(value)) }}
          className={classes.cell}
        />
      );
    case TypeFieldEnum.VOLUME_LINK:
      const volumeAvg = NumberObj.create(value, TypeNumberEnum.NUMBER);
      const boldVolumeColor = Number(value) >= 5000 && Number(value < 10000);
      return (
        <Table.Cell
          {...preparedProps}
          value={volumeAvg.toString()}
          style={{ color: getVolumeColor(Number(value)) }}
          className={clsx(classes.cell, classes.cellLink, { [classes.boldCell]: boldVolumeColor })}
          onClick={
            (e: React.ChangeEvent<{}>) => onClick(
              e, column.name, value, tableRow.row, TypeFieldEnum.VOLUME_LINK,
            )
          }
        />
      );
    case TypeFieldEnum.PERCENT_ORANGE:
      const percentOrange = NumberObj.create(value, TypeNumberEnum.PERCENT);
      return (
        <Table.Cell
          {...preparedProps}
          value={percentOrange.toString()}
          className={clsx(classes.cell, classes.yellow)}
        />
      );
    case TypeFieldEnum.COMPARE_WITH_PERCENT:
      const percent1 = NumberObj.create(value, TypeNumberEnum.PERCENT);
      return (
        <Table.Cell
          {...preparedProps}
          value={percent1.toString()}
          className={clsx(classes.cell,
            { [classes.increasing]: percent1.value > 0 }, { [classes.decreasing]: percent1.value < 0 })}
        />
      );
    case TypeFieldEnum.COMPARE_BG_WITH_PERCENT:
      const percentBg1 = NumberObj.create(value, TypeNumberEnum.PERCENT);
      return (
        <Table.Cell
          {...preparedProps}
          value={percentBg1.toString()}
          className={clsx(classes.cell,
            { [classes.gray]: themeName === ThemeEnum.DARK },
            { [classes.lightGray]: themeName === ThemeEnum.LIGHT },
            { [classes.increasing]: percentBg1.value > 0 }, { [classes.decreasing]: percentBg1.value < 0 })}
        />
      );
    case TypeFieldEnum.DELTA_COMPARE_BG_WITH_PERCENT:
      const percentBg2 = NumberObj.create(value, TypeNumberEnum.PERCENT);
      return (
        <Table.Cell
          {...preparedProps}
          value={percentBg2.toString()}
          className={clsx(classes.cell,
            { [classes.darkGray]: themeName === ThemeEnum.DARK },
            { [classes.darkerGray]: themeName === ThemeEnum.LIGHT },
            { [classes.increasing]: percentBg2.value > 0 }, { [classes.decreasing]: percentBg2.value < 0 })}
        />
      );
    case TypeFieldEnum.COMPARE_WITH_PERCENT_BID:
      const percentBid = NumberObj.create(value, TypeNumberEnum.PERCENT);
      return (
        <Table.Cell
          {...preparedProps}
          value={percentBid.toString()}
          className={clsx(classes.cell,
            { [classes.increasing]: percentBid.value < 0 }, { [classes.decreasing]: percentBid.value > 0 })}
        />
      );
    case TypeFieldEnum.FULL_BID:
      const bidValue = value;
      const bidSign = tableRow.row.sign;
      const bidFormat = tableRow.row.format;
      const bid = bidSign + bidValue + bidFormat;
      return (
        <Table.Cell
          {...preparedProps}
          value={bid.toString()}
          className={clsx(classes.cell)}
        />
      );
    case TypeFieldEnum.PERCENT_INTENT:
      const percentIntent = NumberObj.create(value, TypeNumberEnum.PERCENT);
      return (
        <Table.Cell
          {...preparedProps}
          value={percentIntent.toString()}
          className={clsx(classes.cell, classes[getIntentColor(value)])}
        />
      );
    case TypeFieldEnum.COMPARE_WITH_PERCENT_CR:
      const percentCR = NumberObj.create(value, TypeNumberEnum.PERCENT);
      return (
        <Table.Cell
          {...preparedProps}
          value={percentCR.toString()}
          className={clsx(classes.cell,
            { [classes.increasing]: percentCR.value > 3 },
            { [classes.decreasing]: percentCR.value <= 0 },
            { [classes.pink]: (percentCR.value <= 1 && percentCR.value > 0) },
            { [classes.yellow]: (percentCR.value <= 3 && percentCR.value > 1) })}
        />
      );
    case TypeFieldEnum.COMPARE_BG_WITH_PERCENT_CR:
      const percentBgCR = NumberObj.create(value, TypeNumberEnum.PERCENT);
      return (
        <Table.Cell
          {...preparedProps}
          value={percentBgCR.toString()}
          className={clsx(classes.cell,
            { [classes.gray]: themeName === ThemeEnum.DARK },
            { [classes.lightGray]: themeName === ThemeEnum.LIGHT },
            { [classes.increasing]: percentBgCR.value > 3 },
            { [classes.decreasing]: percentBgCR.value <= 0 },
            { [classes.pink]: (percentBgCR.value <= 1 && percentBgCR.value > 0) },
            { [classes.yellow]: (percentBgCR.value <= 3 && percentBgCR.value > 1) })}
        />
      );
    case TypeFieldEnum.PERCENT_ROI_REPORT:
      const percentROIReport = NumberObj.create(value, TypeNumberEnum.PERCENT);
      return (
        <Table.Cell
          {...preparedProps}
          value={percentROIReport.toString()}
          className={clsx(classes.cell,
            { [classes.increasing]: percentROIReport.value > 75 },
            { [classes.brightGreenBold]: (percentROIReport.value >= 45 && percentROIReport.value < 75) },
            { [classes.lightGreen]: (percentROIReport.value >= 25 && percentROIReport.value < 45) },
            { [classes.yellow]: (percentROIReport.value >= 10 && percentROIReport.value < 25) },
            { [classes.decreasing]: percentROIReport.value < 10 })}
        />
      );
    case TypeFieldEnum.PERCENT_INTENT_RELATED_CR_REPORT:
      const percentCRReport = NumberObj.create(value, TypeNumberEnum.PERCENT);
      return (
        <Table.Cell
          {...preparedProps}
          value={percentCRReport.toString()}
          className={clsx(classes.cell,
            { [classes.increasing]: percentCRReport.value > 9 },
            { [classes.decreasing]: percentCRReport.value <= 0 },
            { [classes.pink]: (percentCRReport.value <= 3 && percentCRReport.value > 0) },
            { [classes.yellow]: (percentCRReport.value <= 9 && percentCRReport.value > 3) })}
        />
      );
    case TypeFieldEnum.COMPARE_WITH_CURRENCY:
      const currency2 = NumberObj.create(value, TypeNumberEnum.CURRENCY);
      return (
        <Table.Cell
          {...preparedProps}
          value={currency2.toString()}
          className={clsx(classes.cell,
            { [classes.increasing]: currency2.value > 0 }, { [classes.decreasing]: currency2.value < 0 })}
        />
      );
    case TypeFieldEnum.EDR_CURRENCY:
      // const edrValue = value.split('!')[1];
      // const edrCurrency = NumberObj.create(Number(edrValue || value), TypeNumberEnum.CURRENCY);
      const edrCurrency = NumberObj.create(value, TypeNumberEnum.CURRENCY);
      return (
        <Table.Cell
          {...preparedProps}
          value={edrCurrency.toString()}
          className={clsx(classes.cell)}
            // { [classes.lightBlue]: edrValue },
        />
      );
    case TypeFieldEnum.ROI_CURRENCY:
      // const roiValue = value.split('!')[1];
      // const roiValueCurrency = NumberObj.create(Number(roiValue || value), TypeNumberEnum.CURRENCY);
      const roiValueCurrency = NumberObj.create(value, TypeNumberEnum.CURRENCY);
      return (
        <Table.Cell
          {...preparedProps}
          value={roiValueCurrency.toString()}
          className={clsx(classes.cell,
            // { [classes.lightBlue]: roiValue },
            { [classes.increasing]: (roiValueCurrency.value) > 0 },
            { [classes.decreasing]: (roiValueCurrency.value) < 0 })}
        />
      );
    case TypeFieldEnum.EDR_DELTA_CURRENCY:
      const edrDeltaCurrency = NumberObj.create(value, TypeNumberEnum.CURRENCY);
      return (
        <Table.Cell
          {...preparedProps}
          value={edrDeltaCurrency.toString()}
          className={clsx(classes.cell,
            { [classes.increasing]: edrDeltaCurrency.value > 0 }, { [classes.decreasing]: edrDeltaCurrency.value < 0 })}
        />
      );
    case TypeFieldEnum.COMPARE_WITH_CURRENCY_GRADIENT:
      const currencyGradient = NumberObj.create(value, TypeNumberEnum.CURRENCY);
      return (
        <Table.Cell
          {...preparedProps}
          value={currencyGradient.toString()}
          className={clsx(classes.cell, classes[getCurrencyGradientColor(value)])}
        />
      );
    case TypeFieldEnum.COMPARE_WITH_CURRENCY_DECREASE:
      const currencyDecrease = NumberObj.create(value, TypeNumberEnum.CURRENCY);
      return (
        <Table.Cell
          {...preparedProps}
          value={currencyDecrease.toString()}
          className={clsx(classes.cell, classes[getCurrencyDecreaseColor(value)])}
        />
      );
    case TypeFieldEnum.COMPARE_WITH_CURRENCY_BORDER:
      const currency3 = NumberObj.create(value, TypeNumberEnum.CURRENCY);
      return (
        <Table.Cell
          {...preparedProps}
          style={{ borderRight: '1px solid rgba(81, 81, 81, 1)' }}
          value={currency3.toString()}
          className={clsx(classes.cell,
            { [classes.increasing]: currency3.value > 0 }, { [classes.decreasing]: currency3.value < 0 })}
        />
      );
    case TypeFieldEnum.INCREASING_DECREASING_CURRENCY:
      const currency6 = NumberObj.create(value, TypeNumberEnum.CURRENCY);
      return (
        <Table.Cell
          {...preparedProps}
          value={currency6.toString()}
          className={clsx(classes.cell,
            { [classes.increasing]: currency6.value > 0 }, { [classes.decreasing]: currency6.value < 0 })}
        />
      );
    case TypeFieldEnum.INCREASING_DECREASING_CURRENCY_MTD_BG:
      const currency7 = NumberObj.create(value, TypeNumberEnum.CURRENCY);
      return (
        <Table.Cell
          {...preparedProps}
          value={currency7.toString()}
          className={clsx(classes.cell,
            // { [classes.yellowBg]: (themeName === ThemeEnum.DARK && getMTDBg(column.name, tableRow.row)) },
            // { [classes.yellowLightBg]: (themeName === ThemeEnum.LIGHT && getMTDBg(column.name, tableRow.row)) },
            { [classes.increasing]: currency7.value > 0 }, { [classes.decreasing]: currency7.value < 0 })}
        />
      );
    case TypeFieldEnum.INCREASING_DECREASING_PERCENT:
      const percent3 = NumberObj.create(value, TypeNumberEnum.PERCENT);
      return (
        <Table.Cell
          {...preparedProps}
          value={percent3.toString()}
          className={clsx(classes.cell,
            { [classes.increasing]: percent3.value > 0 }, { [classes.decreasing]: percent3.value < 0 })}
        />
      );
    case TypeFieldEnum.BORDER:
      return (
        <Table.Cell {...preparedProps} name={column.name} style={{ borderRight: '1px solid rgba(81, 81, 81, 1)' }} />
      );
    case TypeFieldEnum.BORDER_DECREASING_DATE:
      return (
        <Table.Cell
          {...preparedProps}
          name={column.name}
          style={{ borderRight: '1px solid rgba(81, 81, 81, 1)' }}
          className={clsx(className, { [classes.decreasing]: tableRow.row.decreasing })}
        />
      );
    case TypeFieldEnum.COMPARE_GROUP:
      const number2 = NumberObj.create(value);
      return (
        <Table.Cell
          {...preparedProps}
          value={number2.toString()}
          className={clsx(classes.cell,
            { [classes.increasing]: number2.value > 0 }, { [classes.decreasing]: number2.value < 0 })}
        />
      );
    case TypeFieldEnum.COMPARE_GROUP_BG:
      const number3 = NumberObj.create(value);
      return (
        <Table.Cell
          {...preparedProps}
          value={number3.toString()}
          className={clsx(classes.cell,
            { [classes.darkGray]: themeName === ThemeEnum.DARK },
            { [classes.darkerGray]: themeName === ThemeEnum.LIGHT },
            { [classes.increasing]: number3.value > 0 }, { [classes.decreasing]: number3.value < 0 })}
        />
      );
    case TypeFieldEnum.COMPARE_GROUP_BG_PERCENT:
      const percent4 = NumberObj.create(value, TypeNumberEnum.PERCENT);
      return (
        <Table.Cell
          {...preparedProps}
          value={percent4.toString()}
          className={clsx(classes.cell,
            { [classes.darkGray]: themeName === ThemeEnum.DARK },
            { [classes.lightGray]: themeName === ThemeEnum.LIGHT },
            { [classes.increasing]: percent4.value > 0 }, { [classes.decreasing]: percent4.value < 0 })}
        />
      );
    case TypeFieldEnum.DELTA_COMPARE_GROUP_BG_PERCENT:
      const percent5 = NumberObj.create(value, TypeNumberEnum.PERCENT);
      return (
        <Table.Cell
          {...preparedProps}
          value={percent5.toString()}
          className={clsx(classes.cell,
            { [classes.darkGray]: themeName === ThemeEnum.DARK },
            { [classes.darkerGray]: themeName === ThemeEnum.LIGHT },
            { [classes.increasing]: percent5.value > 0 }, { [classes.decreasing]: percent5.value < 0 })}
        />
      );
    case TypeFieldEnum.GRADIENT_ARROW:
      // TODO: tableRow.row.avgCpcDelta
      const lineBoundaryArrow = tableRow.row.avgCpcDelta >= 0
        ? props.row.dataGradient[`max${column.name}`]
        : props.row.dataGradient[`min${column.name}`];

      const currency4 = NumberObj.create(value, TypeNumberEnum.CURRENCY);
      return (
        <Table.Cell
          {...preparedProps}
          className={classes.cell}
          style={{ color: getGradient(Number(tableRow.row.avgCpcDelta), lineBoundaryArrow) }}
        >
          {
              tableRow.row.avgCpcDelta ? (
                <Tooltip title={tableRow.row.avgCpcDelta} placement="top">
                  <section className={classes.containerFlex}>
                    <span>{currency4.toString()}</span>
                    <IconUI
                      path={tableRow.row.avgCpcDelta > 0 ? ArrowUpIcon : ArrowDownIcon}
                      size={0.7}
                      className={classes.arrow}
                    />
                  </section>
                </Tooltip>
              ) : (
                currency4.value
              )
            }

        </Table.Cell>
      );
    case TypeFieldEnum.GRADIENT_PERCENT:
      const percent2 = NumberObj.create(value, TypeNumberEnum.PERCENT);
      const lineBoundaryPer = percent2.value >= 0 ? props.row.dataGradient[`max${column.name}`] : props.row.dataGradient[`min${column.name}`];
      return (
        <Table.Cell
          {...preparedProps}
          value={percent2.toString()}
          className={classes.cell}
          style={{ color: getGradient(percent2.value, lineBoundaryPer) }}
        />
      );
    case TypeFieldEnum.GRADIENT_CURRENCY:
      const currency5 = NumberObj.create(value, TypeNumberEnum.CURRENCY);
      const lineBoundaryCur = currency5.value >= 0 ? props.row.dataGradient[`max${column.name}`] : props.row.dataGradient[`min${column.name}`];
      return (
        <Table.Cell
          {...preparedProps}
          value={currency5.toString()}
          className={classes.cell}
          style={{ color: getGradient(currency5.value, lineBoundaryCur) }}
        />
      );
    case TypeFieldEnum.CONTENT:
      return (
        <Table.Cell {...preparedProps} className={classes.cellController}>
          <section className={classes.containerCellContent}>
            <span className={classes.containerIconContent}>
              {(() => {
                switch (value) {
                  case 'api': return (
                    <Tooltip title="API">
                      <IconUI path={ApiIcon} size={0.7} />
                    </Tooltip>
                  );
                  case 'realtime': return (
                    <Tooltip title="Real time">
                      <IconUI path={RealtimeIcon} size={0.7} />
                    </Tooltip>
                  );
                  default: return (
                    <Tooltip title="XML">
                      <IconUI path={XmlIcon} size={0.7} />
                    </Tooltip>
                  );
                }
              })()}
            </span>
          </section>
        </Table.Cell>
      );
    case TypeFieldEnum.COLUMN_BAR:
      const valueCell = Number(value || 0);
      // const fillPercent = valueCell > tableRow.row.mtdRevenue ? Number(tableRow.row.mtdRevenue / valueCell) * 100 : 0;
      const fillPercent = fillBarPercent(tableRow.row.budget, tableRow.row.mtdRevenue);
      const fillPercentEom = fillBarPercent(tableRow.row.budget, tableRow.row.eom);
      // const redLineValue = valueCell > (tableRow.row.budget || tableRow.row.eom)
      //   ? Number((tableRow.row.budget || tableRow.row.eom) / valueCell) * 100
      //   : 0;

      const redLineValue = Number(tableRow.row.budget) > 0
        ? Number(valueCell / tableRow.row.budget) * 100
        : 0;

      const style = {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridColumnGap: '8px',
        fontSize: '12px',
        padding: '4px',
        gridRowGap: '4px',
      };

      const values = [
        { name: 'Projected', value: NumberObj.create(tableRow.row.eom, TypeNumberEnum.CURRENCY) },
        { name: 'MTD', value: NumberObj.create(tableRow.row.mtdRevenue, TypeNumberEnum.CURRENCY) },
        { name: 'Budget/IO', value: NumberObj.create(tableRow.row.budget, TypeNumberEnum.CURRENCY) },
        { name: 'Goal', value: NumberObj.create(tableRow.row.goal, TypeNumberEnum.CURRENCY) },
      ];

      const html = (
        <div style={style}>
          {
            values.map((item) => (
              <>
                <div className={clsx(
                  { [classes.yellow]: item.name === 'Projected' },
                  { [classes.increasing]: item.name === 'MTD' },
                )}
                >
                  {`${item.name} $`}
                </div>
                <div>{item.value.toString()}</div>
              </>
            ))
          }
        </div>
      );

      return (
        <Table.Cell {...preparedProps}>
          <Tooltip title={html}>
            <Box onClick={(e: React.ChangeEvent<{}>) => onClick(
              e, column.name, valueCell, tableRow.row, TypeFieldEnum.COLUMN_BAR,
            )}
            >
              <ColumnProgressUI
                value={fillPercent}
                redLineValue={redLineValue}
                percentEom={fillPercentEom}
              />
            </Box>
          </Tooltip>
        </Table.Cell>
      );

    case TypeFieldEnum.LINEAR_DAY_TARGET:
      const valueDayTargetCell = tableRow.row.budget > 0 ? Number(value || 0) : 0;
      const fillDayTargetPercent = fillBarPercent(tableRow.row.budget, valueDayTargetCell);

      const styleDayTarget = {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridColumnGap: '8px',
        fontSize: '12px',
        padding: '4px',
        gridRowGap: '4px',
      };

      const dayTargetValues = [
        { name: 'Day Target', value: NumberObj.create(tableRow.row.dayTarget, TypeNumberEnum.CURRENCY) },
      ];

      const htmlDayTarget = (
        <div style={styleDayTarget}>
          {
            dayTargetValues.map((item) => (
              <>
                <div className={clsx(
                  { [classes.yellow]: item.name === 'Projected' },
                  { [classes.increasing]: item.name === 'MTD' },
                )}
                >
                  {`${item.name} $`}
                </div>
                <div>{item.value.toString()}</div>
              </>
            ))
          }
        </div>
      );

      return (
        <Table.Cell {...preparedProps}>
          <Tooltip title={htmlDayTarget}>
            <Box onClick={(e: React.ChangeEvent<{}>) => onClick(
              e, column.name, valueDayTargetCell, tableRow.row, TypeFieldEnum.LINEAR,
            )}
            >
              <LinearSimpleProgressUI
                value={fillDayTargetPercent}
              />
            </Box>
          </Tooltip>
        </Table.Cell>
      );

    case TypeFieldEnum.TOOLTIP_HISTORY:
      const valueHistoryCell = tableRow.row.jobsCountHistory;

      const historyBlockStyle = {
        display: 'block',
        padding: '4px',
      };

      const historyValueStyle = {
        display: 'block',
        fontSize: '12px',
        padding: '4px',
      };

      const historyHtml = (
        <div style={historyBlockStyle}>
          {
            valueHistoryCell.map((item: { key: string; name: string }) => (
              <div style={historyValueStyle} key={`${item?.key}-valueHistoryCell`}>{item.name.toString()}</div>
            ))
          }
        </div>
      );

      return (
        <Table.Cell {...preparedProps}>
          {valueHistoryCell && valueHistoryCell.length > 0 ? (
            <Tooltip title={historyHtml}>
              <span>{value}</span>
            </Tooltip>
          ) : (
            <span>{value}</span>
          )}
        </Table.Cell>
      );
    case TypeFieldEnum.TOOLTIP_FRAUD_SCORE:
      const fraudStyle = {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridColumnGap: '16px',
        fontSize: '12px',
        padding: '4px',
        gridRowGap: '4px',
      };

      const fraudValues = [
        { name: 'Abuse velocity', value: tableRow.row.ipqsAbuseVelocity },
        { name: 'TOR network detected', value: tableRow.row.ipqsRecentAbuse ? 'Yes' : 'No' },
        { name: 'Active TOR network detected', value: tableRow.row.ipqsActiveTor ? 'Yes' : 'No' },
        { name: 'TOR network detected', value: tableRow.row.ipqsTor ? 'Yes' : 'No' },
        { name: 'Active VPN detected', value: tableRow.row.ipqsActiveVpn ? 'Yes' : 'No' },
        { name: 'VPN detected', value: tableRow.row.ipqsVpn ? 'Yes' : 'No' },
        { name: 'Bot network detected', value: tableRow.row.ipqsBotStatus ? 'Yes' : 'No' },
        { name: 'IP is a Crawler', value: tableRow.row.ipqsCrawler ? 'Yes' : 'No' },
        { name: 'Proxy network detected', value: tableRow.row.ipqsProxy ? 'Yes' : 'No' },
      ];

      const fraudHtml = (
        <div style={fraudStyle}>
          {
            fraudValues.map((item) => (
              <>
                <div>{item.name}</div>
                <div>{item.value.toString()}</div>
              </>
            ))
          }
        </div>
      );

      return (
        <Table.Cell {...preparedProps} className={classes.cursor}>
          <Tooltip classes={{ tooltip: tooltipFraud }} title={fraudHtml}>
            <span>{value}</span>
          </Tooltip>
        </Table.Cell>
      );
    case TypeFieldEnum.CELL_CHECKBOX:
      return (
        <Table.Cell {...preparedProps} className={classes.cellController}>
          <Checkbox
            color="secondary"
            size="small"
            edge="start"
            tabIndex={-1}
            id={value}
            checked={value}
            // checked={statusCheckbox[table] ? statusCheckbox[table][tableRow.row.id] === true : false}
            inputProps={{ 'aria-label': 'controlled' }}
            className={classes.cellCheckbox}
            onChange={(e: React.ChangeEvent<{}>) => {
              // handleCheckbox(table, tableRow.row.id);
              onClick(
                e, column.name, value, tableRow.row, TypeFieldEnum.CELL_CHECKBOX,
              );
            }}
          />
        </Table.Cell>
      );
    case TypeFieldEnum.CHECKBOX:
      return (
        <Table.Cell {...preparedProps} className={classes.cellController}>
          <Checkbox
            color="secondary"
            size="small"
            edge="start"
            tabIndex={-1}
            id={value}
            className={classes.cellCheckbox}
          />
        </Table.Cell>
      );
    case TypeFieldEnum.TEXT_FEED_STATUS:
      return (
        <Table.Cell
          {...preparedProps}
          name={column.name}
          className={clsx(className, classes[getFeedStatusColor(value)])}
        />
      );
    case TypeFieldEnum.TEXT_WEBSITE_STATUS:
      return (
        <Table.Cell
          {...preparedProps}
          name={column.name}
          className={clsx(className, classes[getWebsiteStatusColor(value)])}
        />
      );
    case TypeFieldEnum.TEXT_LINK:
      return (
        <Table.Cell {...preparedProps} name={column.name} className={clsx(className, classes.cellLink)}>
          <Link href={tableRow.row.url} target="_blank" color="inherit">{value}</Link>
        </Table.Cell>
      );
    case TypeFieldEnum.TEXT_LINK2:
      return (
        <Table.Cell {...preparedProps} name={column.name} className={clsx(className, classes.cellLink)}>
          <Link href={value} target="_blank" color="inherit">{value}</Link>
        </Table.Cell>
      );
    case TypeFieldEnum.TEXT_NA_COLORED:
      return (
        <Table.Cell {...preparedProps} name={column.name} className={clsx(className, classes[getNAColor(value)])}>
          {value.trim() === '' ? 'N/A' : value}
        </Table.Cell>
      );
    case TypeFieldEnum.BUTTON:
      return (
        <Table.Cell
          {...preparedProps}
          name={column.name}
          // className={clsx(className, classes.cellLink, { [classes.decreasing]: tableRow.row.decreasing })}
        >
          <Button
            color="primary"
            className={classes.button}
            onClick={(e: React.ChangeEvent<{}>) => onClick(
              e, column.name, value, tableRow.row, TypeFieldEnum.BUTTON,
            )}
          >
            {title}
          </Button>
        </Table.Cell>
      );
    case TypeFieldEnum.BUTTON_TEXT:
      return (
        <Table.Cell
          {...preparedProps}
          name={column.name}
        >
          <Button
            color="primary"
            className={classes.button}
            onClick={(e: React.ChangeEvent<{}>) => onClick(
              e, column.name, value, tableRow.row, TypeFieldEnum.BUTTON_TEXT,
            )}
          >
            {value}
          </Button>
        </Table.Cell>
      );
    case TypeFieldEnum.BUTTON_OR_STRING:
      if (value) {
        return <Table.Cell {...preparedProps} name={column.name} />;
      }
      return (
        <Table.Cell
          {...preparedProps}
          name={column.name}
        >
          <Button
            color="primary"
            className={classes.button}
            onClick={(e: React.ChangeEvent<{}>) => onClick(
              e, column.name, value, tableRow.row, TypeFieldEnum.BUTTON_OR_STRING,
            )}
          >
            {title}
          </Button>
        </Table.Cell>
      );
    case TypeFieldEnum.BUTTON_OR_EMPTY:
      if (!value) {
        return <Table.Cell {...preparedProps} name={column.name} />;
      }
      return (
        <Table.Cell
          {...preparedProps}
          name={column.name}
        >
          <Button
            color="primary"
            className={classes.button}
            onClick={(e: React.ChangeEvent<{}>) => onClick(
              e, column.name, value, tableRow.row, TypeFieldEnum.BUTTON_OR_EMPTY,
            )}
          >
            {title}
          </Button>
        </Table.Cell>
      );
    case TypeFieldEnum.DATE_SHORT:
      const dateShort = new Date(value);
      return (
        <Table.Cell {...preparedProps} value={isValidDate(dateShort) ? format(dateShort, 'M/dd @ HH:mm:ss') : value} name={column.name} />
      );
    default:
      return (
        <Table.Cell
          {...preparedProps}
          name={column.name}
          className={clsx({ [classes.decreasing]: value === 'none' })}
        />
      );
  }

  // function handleCheckbox(table: string, item: string) {
  //   setStatusCheckbox({ ...statusCheckbox, [table]: { [item]: !statusCheckbox[table][item] } });
  // }
};

export default Cell;
